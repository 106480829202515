import React from 'react'

const DropdownItem = ({children, active, forInput}) => {
  return (
    <label htmlFor={forInput} className={active ? "Dropdown-item Dropdown-item--active" : "Dropdown-item"}>
      {children}
    </label>
  )
}

export default DropdownItem;
