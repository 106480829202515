import { React, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppState } from "states/appState";
import { Navigate } from "react-router-dom";
import { redirectToLogout } from "utils/auth0/common";
import Layout from "utils/layout/Layout.jsx";
import { routes } from "utils/auth0/constants";

const MissingEmail = () => {
  const { t } = useTranslation();
  const [appContext] = useAppState();

  useEffect(() => {
    document.title = t("metaTags.pagetitle.missingEmail");
    // eslint-disable-next-line
  }, []);

  if (appContext.session_token.email) {
    return <Navigate replace to={routes.Error} />;
  }

  function returnToLogin(e) {
    e.preventDefault();
    redirectToLogout(
      appContext.session_token.clientId,
      appContext.session_token.redirectUri,
      appContext.session_token.amplitudeId,
      appContext.session_token.adjustId
    );
  }

  return (
    <>
      <Layout
        title={t("missing_email.page")}
        containerClass="Profile-content--flCenter"
        iconHeaderLeft="true"
      >
        {appContext.styles.logoUrl && (
          <img
            height="32"
            className="Profile-logo"
            src={appContext.styles.logoUrl}
            alt="logo"
          />
        )}
        {!appContext.styles.logoUrl && <br />}
        <h2 className="Profile-title Profile-title--sub">
          {t("missing_email.title")}
        </h2>
        <p className="Profile-text">{t("missing_email.text")}</p>
        {appContext.session_token.connection &&
          appContext.session_token.connection === "facebook" && (
            <a
              className="Button"
              href="https://www.facebook.com/settings/?tab=applications"
            >
              {t("missing_email.facebook")}
            </a>
          )}
        {appContext.session_token.clientId &&
          appContext.session_token.redirectUri && (
            <a
              className="Button Button--outlined"
              href="#!"
              onClick={returnToLogin}
            >
              {t("missing_email.backToBtn")}
            </a>
          )}
      </Layout>
    </>
  );
};

export default MissingEmail;
