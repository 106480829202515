import React, { useState, useEffect } from "react";
import {DropdownList} from "./DropdownList";
import DropdownItem from "./DropdownItem";
import DropdownHeading from "./DropdownHeading";
import * as Icon from '../../Icons/index';
import Label from "../Label";
import Input from "../Input";
import FormGroup from "../FormGroup";
import {useTranslation} from "react-i18next";

function Overlay({isOpen}) {
  if (!isOpen) {
    document.body.classList.remove('Dropdown-lock');
    return null;
  }
  document.body.classList.add('Dropdown-lock');
  return <div className="Dropdown-overlay"></div>;
}

function GetValueOfSelectedOption({selectedOption, placeholder, required}) {
  if(!!selectedOption) {
    return (
      <>
        {selectedOption.image && <img className="Dropdown-logo" width="50" height="50" src={selectedOption.image} alt={selectedOption.label} />}
        <span className="Dropdown-label">{selectedOption.label}</span>
        <input name="favoriteClub" type="hidden" value={selectedOption.label} required={required === true ? required : null}/>
      </>
    );
  } else {
    return (
      <>
        {placeholder}
        <input name="favoriteClub" type="hidden" value="" required={required === true ? required : null} />
      </>
    );
  }
}

const DropdownWithSearch = ({
                              id,
                              datas,
                              label,
                              required,
                              showError,
                              placeholder,
                              selectedOptions,
                              onChange,
                            }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [dropdownOptions, setDropdownOptions] = useState(datas);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    const filteredOptions = datas.map((data) => {
      const searchList = data.options.filter((option) => {
         return option.label
           .toLowerCase()
           .includes(searchTerm.toLowerCase());
      });

      return {...data, 'options': searchList}
    });

    if (selectedOptions !== "") {
      setSelectedOption(selectedOptions);
    }

    setDropdownOptions(filteredOptions);
  }, [searchTerm, datas, selectedOptions]);

  const handleToggle = () => {
    setIsOpen(true);
    setSearchTerm('');
  }

  const RenderDropdownOptions = () => {
    return dropdownOptions.map((data, index) => {
      if(data.options.length !== 0) {
        return (
          <div id={`ligue${parseInt(index)+1}`} key={index}>
            <DropdownHeading key={'group-'+index} data={data} />
            {data.options.map((data) => {
              const isChecked = !!selectedOption && selectedOption.value === data.value;
              return (
                <DropdownItem
                  key={'club-'+data.value}
                  active={isChecked}
                  forInput={data.value}
                >
                  <input
                    id={data.value}
                    name='favoriteTeam'
                    type="radio"
                    className="Dropdown-radio"
                    checked={isChecked}
                    required={required}
                    onChange={() => {setSelectedOption(data); onChange(data);}}
                    disabled={data.disabled}
                  />
                  {data.image !== "" ? <img className="Dropdown-logo" width="50" height="50" src={data.image} alt={data.label} loading="lazy" /> : <div className="Dropdown-noLogo"></div>}
                  <span>{data.label}</span>
                </DropdownItem>
              );
            })}
          </div>
        );
      } else {
        return (
          <div key={index}>
            <DropdownHeading key={index} data={data} />
            <span className="Dropdown-error">{t('profile.input.errorClub')}</span>
          </div>
        )
      }
    });
  };

  return (
    <>
      <FormGroup>
        <div className="FormElement-dropdown Dropdown">
          <button type="button" aria-expanded={isOpen} className={showError ? `Dropdown-toggle Dropdown-toggle--error` : "Dropdown-toggle"} onClick={handleToggle}>
            <GetValueOfSelectedOption selectedOption={selectedOption} placeholder={placeholder} required={required}/>
            <Icon.ChevronDown />
          </button>
          <Label required="true" className="FormElement-label--top" id={id} text={label} showError={showError}/>
          <DropdownList show={isOpen} onClickOutside={() => {setIsOpen(false)}}>
            <FormGroup className="FormElement-group--search">
              <Input id="search" className="FormElement-input--search" value={searchTerm} setStateParent={setSearchTerm}
                     onChange={(e) => setSearchTerm(e.target.value)} placeholder={t('profile.input.search')} autoFocus/>
              <Label text={t('profile.input.search')} id="search" className="FormElement-label--search" />
            </FormGroup>
            <div className={'Dropdown-options'}>
              <RenderDropdownOptions />
            </div>
            <button type="button" className="Button Dropdown-button" onClick={() => {setIsOpen(false)}} data-action="close">{t('profile.input.searchCloseBtn')}</button>
          </DropdownList>
          <Overlay isOpen={isOpen}/>
        </div>
      </FormGroup>
    </>
  );
};

export default DropdownWithSearch;
