import React, {useState} from 'react'
import { createGlobalStyle } from 'styled-components';
import { useAppState } from "states/appState";

const GlobalStyle = () => {
  const [appState] = useAppState();

  const [custom] = useState({
    ...appState.styles
  });

  const GlobalStyleVariable = createGlobalStyle`
    @font-face {
      font-family: "CustomFont";
      font-style: normal;
      src: url(${custom.font}) format("woff2");
      font-display: swap;
    }
    @font-face {
        font-family: "CustomFontSecondary";
        font-style: normal;
        src: url(${custom.fontSecondary}) format("woff2");
        font-display: swap;
    }
    html {
      --color-primary: ${custom.colorPrimary};
      --color-secondary: ${custom.colorSecondary};
      --color-background: ${custom.colorBackground};
      --color-backgroundBloc: ${custom.colorBackgroundBloc};
      --color-backgroundBloc2: ${custom.colorBackgroundBloc2};
      --color-text: ${custom.colorText};
      --color-text-filled: ${custom.colorTextFilled};
      --color-focus: ${custom.colorFocus};
      --color-error: ${custom.colorError};
      --background-image: url('${custom.backgroundImage}');
      --button-text-color: ${custom.buttonTextColor};
      }  
`;

  return (
    <GlobalStyleVariable />
  );
}
export default GlobalStyle;
