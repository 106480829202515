import React from "react";
import * as Icon from "../../components/Icons";
import { useAppState } from "states/appState";
import { redirectToLogout } from "utils/auth0/common";

const Layout = ({
  children,
  title,
  containerClass,
  iconHeaderLeft,
  iconHeaderRight,
  iconRedirectURL,
}) => {
  const [appContext] = useAppState();

  function redirect(e) {
    e.preventDefault();
    if (
      appContext.session_token.clientId &&
      appContext.session_token.redirectUri
    ) {
      redirectToLogout(
        appContext.session_token.clientId,
        appContext.session_token.redirectUri,
        appContext.session_token.amplitudeId,
        appContext.session_token.adjustId
      );
    } else {
      window.location.replace(appContext.access_token.redirectUri);
    }
  }

  var iconHeaderVisible =
    (appContext.session_token.clientId &&
      appContext.session_token.redirectUri) ||
    appContext.access_token.redirectUri;

  return (
    <>
      <div className="Layout">
        <div className="Layout-container">
          <header className="Layout-header">
            {iconHeaderLeft && iconHeaderVisible && (
              <Icon.Cross
                className="Layout-icon Layout-icon--left"
                onClick={redirect}
              />
            )}
            <h1 className="Layout-title">{title}</h1>
            {iconHeaderRight && iconHeaderVisible && (
              <Icon.Cross
                className="Layout-icon Layout-icon--right"
                onClick={redirect}
              />
            )}
            {iconHeaderRight && iconRedirectURL && (
              <a
                className="Layout-icon Layout-icon--right"
                href={iconRedirectURL}
              >
                <Icon.Cross />
              </a>
            )}
          </header>
          <div
            className={`Layout-content ${containerClass ? containerClass : ""}`}
          >
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
