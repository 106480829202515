const optinTypes = {
  NEWSLETTER_L1: "newsletterL1",
  NEWSLETTER_L2: "newsletterL2",
  NEWSLETTER_PARTNERS: "newsletterPartners",
  TICKETING: "ticketing",
  MPG: "mpg",
  MPP: "mpp",
  COACH_L1: "coachL1",
  PLAY_L1: "playL1",
  /** favoriteClubs have a specific type to forge: favoriteClub_[shortLfpClubId] */
  FAVORITE_CLUB_PREFIX: "favoriteClub",
};

const optinStatuses = {
  DISABLED: 0,
  ENABLED: 1,
};

const defaultOptins = [
  {
    Name: "NewsletterL1",
    Value: optinTypes.NEWSLETTER_L1,
    Signup: true
  },
  {
    Name: "NewsletterL2",
    Value: optinTypes.NEWSLETTER_L2,
    Signup: true
  },
  {
    Name: "NewsletterPartners",
    Value: optinTypes.NEWSLETTER_PARTNERS,
    Signup: false
  },
  {
    Name: "Ticketing",
    Value: optinTypes.TICKETING,
    Signup: false
  }
];

const getCurrentUserOptinsAysnc = async (token) => {
  const headers = { Authorization: 'Bearer ' + token };
  const reponse = await fetch(process.env.REACT_APP_FANBASE_API_URL + '/user-optins/me', { headers });
  const userOptins = await reponse.json();
  return userOptins;
}

const updateCurrentUserOptinsAysnc = async (userOptins, token) => {
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token,
    },
    body: JSON.stringify(userOptins),
  };

  await fetch(process.env.REACT_APP_FANBASE_API_URL + '/user-optins/me', requestOptions);
}


const unsubscribeUserOptinsAsync = async (userId, optinType) => {
  if (!userId || !optinType) {
    throw new Error('Missing userId or optinType')
  }

  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      userId,
      optins: [{
        optinType,
        status: optinStatuses.DISABLED,
      }]
    }),
  };
  const response = await fetch(process.env.REACT_APP_FANBASE_API_URL + '/user-optins/user/' + userId, requestOptions);
  if (!response.ok) {
    throw new Error(response.statusText)
  }
}

export { defaultOptins, optinStatuses, optinTypes, getCurrentUserOptinsAysnc, updateCurrentUserOptinsAysnc, unsubscribeUserOptinsAsync }
