import { useEffect } from "react";
import { useAppState } from "states/appState";
import { routes } from "utils/auth0/constants";
import * as amplitude from "@amplitude/analytics-browser";

export default function AmplitudeGlobalDatalayer() {
  const [appContext] = useAppState();

  useEffect(() => {
    const pageName = Object.values(routes)
      .find((v) => v === window.location.pathname)
      ?.replaceAll("/", "-");
    const eventProperties = {
      pageName: pageName?.charAt(0) === "-" ? pageName.substring(1) : pageName,
      platform: "",
    };

    if (appContext.session_token.validToken) {
      eventProperties.platform = appContext.session_token.clientName;
    } else if (appContext.access_token.validToken) {
      eventProperties.platform = appContext.access_token.clientName;
    }

    if (eventProperties.pageName) {
      amplitude.track("pageView", eventProperties);
    }
    // eslint-disable-next-line
  }, [
    appContext.session_token.validToken,
    appContext.session_token.dspId,
    appContext.access_token.validToken,
    appContext.access_token.dspId,
  ]);

  return null;
}
