import { React } from 'react'
import { useAppState } from "states/appState";
import { Navigate } from 'react-router-dom';
import { routes } from 'utils/auth0/constants'

export default function Home() {

  const [appContext] = useAppState();
  let url;

  if(!appContext.session_token.validToken && !appContext.access_token.validToken){
    url = routes.Error;
  }
  else if(appContext.access_token.validToken)
  {
    url = routes.ProfileInfo;
    if(!tokenExistInSessionStorage("access_token")){
      url += "?access_token=" + appContext.access_token.rawToken;
    }
  }
  else {    
    url = "?state=" + appContext.session_token.continueToken;
    if(!tokenExistInSessionStorage("session_token")){
      url += "&session_token=" + appContext.session_token.sessionToken
    }
    if(!appContext.session_token.email){
      url = routes.MissingEmail + url;
    }else if(!appContext.session_token.emailVerified){
      url = routes.VerifyEmail + url;
    }
    else{
      url = routes.CompleteProfile + url;
    }
  }

  return <Navigate replace to={url} />
}

function tokenExistInSessionStorage(key){
  try{
    var sessionValue = window.sessionStorage.getItem(key);
    if(sessionValue?.trim()){
      return true;
    }
  }catch(e) { 
    console.error("sessionStorage is not enabled");
  }
  return false;
}
