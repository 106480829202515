import * as Icon from "../Icons";
import {toast} from "react-hot-toast";
import "./Index.scss"

const Toast = ({t, text, closeBtn = false}) => {
  return (
    <div className={"Toast"}>
      <div className={"Toast-icon"}>
        <Icon.Notif />
      </div>
      <p className={"Toast-text"}>{text}</p>
      {closeBtn && (<button className={"Toast-close"} onClick={() => toast.dismiss(t.id)}>
        <Icon.Cross />
      </button>)}

    </div>
  )
}
export default Toast;
