import * as React from "react";

const Close = ({ titleId, className, ...props }) => (
  <i className={className ? `Icon ${className}` : "Icon"} aria-labelledby={titleId} {...props}>
    <svg fill="currentColor"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 512 512">
        <path className="cls-1"
              d="M355.36 189.86a25.367 25.367 0 0 0 0-19.46 25.1 25.1 0 0 0-5.54-8.23 25.412 25.412 0 0 0-8.23-5.54c-3.08-1.28-6.39-1.94-9.73-1.94s-6.64.66-9.72 1.94a25.255 25.255 0 0 0-8.23 5.54L256 220.33l-57.91-58.16c-4.76-4.76-11.22-7.44-17.96-7.44s-13.19 2.67-17.95 7.44c-4.76 4.76-7.44 11.22-7.44 17.95s2.67 13.19 7.44 17.96l58.16 57.91-58.16 57.91a25.412 25.412 0 0 0-5.54 8.23c-1.28 3.08-1.94 6.39-1.94 9.72s.66 6.64 1.94 9.73c1.28 3.08 3.17 5.88 5.54 8.23 2.35 2.37 5.15 4.25 8.23 5.54a25.367 25.367 0 0 0 19.46 0 25.1 25.1 0 0 0 8.23-5.54l57.91-58.16 57.91 58.16c2.35 2.37 5.15 4.25 8.23 5.54 3.08 1.28 6.39 1.94 9.72 1.94s6.64-.66 9.73-1.94a25.1 25.1 0 0 0 8.23-5.54c2.37-2.35 4.25-5.15 5.54-8.23 1.28-3.08 1.94-6.39 1.94-9.73s-.66-6.64-1.94-9.72a25.1 25.1 0 0 0-5.54-8.23l-58.16-57.91 58.16-57.91c2.37-2.35 4.25-5.15 5.54-8.23Z"/>
        <path className="cls-1"
              d="M491.47 159.29a252.823 252.823 0 0 0-56.68-82.09 252.842 252.842 0 0 0-82.09-56.67A253.066 253.066 0 0 0 255.09 0c-33.58-.29-66.88 6.11-97.96 18.82a252.931 252.931 0 0 0-83.06 55.24 252.828 252.828 0 0 0-55.24 83.06A253.307 253.307 0 0 0 0 255.09a252.96 252.96 0 0 0 20.52 97.62 252.948 252.948 0 0 0 56.67 82.09 253.024 253.024 0 0 0 82.09 56.68A252.922 252.922 0 0 0 256.9 512c33.58.29 66.88-6.1 97.96-18.82a253.087 253.087 0 0 0 83.06-55.24 252.984 252.984 0 0 0 55.24-83.06 252.845 252.845 0 0 0 18.82-97.96 252.96 252.96 0 0 0-20.52-97.62Zm-48.41 174.2a201.047 201.047 0 0 1-43.93 65.64 202.284 202.284 0 0 1-123.19 58.35 202.272 202.272 0 0 1-132.24-33.04 202.3 202.3 0 0 1-74.67-245.58A202.315 202.315 0 0 1 458.31 256c.09 26.59-5.09 52.93-15.25 77.5Z"/>
    </svg>
  </i>
);

export default Close;
