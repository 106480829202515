import "./Index.scss";
import * as Icon from '../Icons/index';

export default function Loader() {
  return (
    <div className="Loader">
      <Icon.Loader />
    </div>
  )
}
