import * as React from "react";

const Cross = ({ titleId, className, ...props }) => (
  <i className={className ? `Icon ${className}` : "Icon"} aria-labelledby={titleId} {...props}>
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" data-name="Calque 1" viewBox="0 0 512 512">
      <path d="M316.11 255.92 499.4 73.06c16.71-16.72 16.71-43.81 0-60.53-16.72-16.71-43.81-16.71-60.53 0L256.01 195.82 73.15 12.53c-16.71-16.71-43.81-16.71-60.52 0-16.71 16.72-16.71 43.81 0 60.53l183.29 182.86L12.63 438.78c-16.71 16.55-16.85 43.51-.31 60.22l.31.31c16.58 16.71 43.57 16.82 60.28.25l.25-.25 182.86-183.29 182.86 183.29c16.56 16.77 43.57 16.94 60.34.38 4.1-4.05 7.34-8.89 9.52-14.23 6.66-16.02 2.96-34.47-9.38-46.67L316.12 255.93Z"/></svg>
  </i>
);

export default Cross;
