import { useEffect, useRef } from 'react';

export function DropdownList(props) {
  const ref = useRef(null);
  const { onClickOutside } = props;

  useEffect(() => {
    const handleClickOutside = (event) => {
      const CLOSE_BTN = !!event.target.dataset.action && event.target.dataset.action === 'close';
      if ((ref.current && !ref.current.contains(event.target)) || CLOSE_BTN) {
        onClickOutside && onClickOutside();
      }
    };

    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  if(!props.show)
    return null;

  return (
    <div ref={ref} className={props.show ? "Dropdown-menu show" : "Dropdown-menu"}>
      {props.children}
    </div> );
}
