import * as React from "react";

const Notif = ({ titleId, className, ...props }) => (
  <i className={className ? `Icon ${className}` : "Icon"} aria-labelledby={titleId} {...props}>
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" data-name="Calque 1" viewBox="0 0 512 512">
      <path d="M229.12 211.46h53.76V405h-53.76z" className="cls-1"/>
      <path d="M256 512c141.38 0 256-114.62 256-256S397.38 0 256 0 0 114.62 0 256s114.62 256 256 256Zm0-460.8c113.11 0 204.8 91.69 204.8 204.8 0 113.11-91.69 204.8-204.8 204.8-113.11 0-204.8-91.69-204.8-204.8 0-113.11 91.69-204.8 204.8-204.8Z"/>
      <circle cx="256" cy="134.66" r="26.88" className="cls-1"/>
    </svg>
  </i>
);

export default Notif;
