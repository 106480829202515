import { React, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import Layout from "../../utils/layout/Layout.jsx";
import DeleteIMG from "../../assets/images/delete.svg";

export default function NotFound() {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('metaTags.pagetitle.error404');
  // eslint-disable-next-line
  }, []);

  return (
    <Layout title={t('error.404.title')} containerClass="Profile-content--flCenter" iconHeaderRight="true">
      <div className="Profile-image">
        <img src={DeleteIMG} alt="delete icon"/>
      </div>
      <p className="Profile-text">
        {t('error.404.text')}
      </p>
    </Layout>
  )
}
