import * as React from "react";

const ChevronDown = ({ titleId, className, ...props }) => (
  <i className={className ? `Icon ${className}` : "Icon"} aria-labelledby={titleId} {...props}>
    <svg fill="currentColor"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 512 303.39">
      <path d="M508.27 29.93a48.724 48.724 0 0 0-10.63-15.81C488.54 5.08 476.23 0 463.4 0s-25.14 5.08-34.24 14.12L254.79 186.06 82.85 14.12C73.75 5.08 61.44 0 48.61 0S23.47 5.08 14.37 14.12c-4.55 4.51-8.16 9.89-10.63 15.81a48.514 48.514 0 0 0 0 37.36A48.724 48.724 0 0 0 14.37 83.1L220.3 289.03c4.52 4.55 9.89 8.16 15.81 10.63a48.514 48.514 0 0 0 37.36 0 48.724 48.724 0 0 0 15.81-10.63L497.64 83.1c4.55-4.52 8.16-9.89 10.63-15.81 2.47-5.92 3.73-12.27 3.73-18.68s-1.27-12.76-3.73-18.68Z"/>
    </svg>
  </i>
);

export default ChevronDown;
