export function redirectToLogout(clientId, redirectUri, amplitudeId, adjustId) {
  var logoutUrl =
    "https://" +
    process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN +
    "/v2/logout?returnTo=https://" +
    process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN +
    "/authorize?response_type=code%26client_id=" +
    clientId +
    "%26redirect_uri=" +
    redirectUri;

  logoutUrl +=
    "&scope=openid%20email%20offline_access&audience=https://" +
    process.env.REACT_APP_AUTH0_DOMAIN +
    "/api/v2/";

  window.location.replace(logoutUrl);
}

export function toBase32(number) {
  const _alphaNumCharset = "123456789ABCDEFGHJKLMNPRSTUVWXYZ";
  let base32String = "";
  if (number === 0) return _alphaNumCharset[0];
  while (number > 0) {
    base32String = `${
      _alphaNumCharset[number % _alphaNumCharset.length]
    }${base32String}`;
    number = Math.floor(number / _alphaNumCharset.length);
  }
  return base32String;
}

export function generateRandomAlias() {
  const currentDate = new Date();
  const startYear = new Date(`${new Date().getFullYear()}-01-01`).getTime();
  const startTime = Math.floor((currentDate.getTime() - startYear) / 1000);

  const currentYear = currentDate.getFullYear() - 2000;
  const buffer = new Uint32Array(1);
  const randomValue = window.crypto.getRandomValues(buffer)[0];
  const randomFloat = randomValue / Math.pow(2, 32);
  return (
    "GOAT#" +
    (toBase32(currentYear) +
      toBase32(startTime) +
      toBase32(Math.floor(randomFloat * (1024 - 32)) + 32))
  );
}

export function setAccessTokenDspInfo(setAppContext, json) {
  setAppContext((values) => ({
    ...values,
    access_token: {
      ...values.access_token,
      dspId: json.id,
      favoriteTeam: json.mainFavoriteClubId,
    },
  }));
}
