import React, {useRef} from 'react'
import * as Icon from '../Icons';

const Input = ({required, type, className, value, id, placeholder, setStateParent, neestedObject, hideReset, ...props}) => {
  const ref = useRef(null);

  function handleResetClick() {
    ref.current.focus();

    if (neestedObject) {
      setStateParent(values => ({...values, [neestedObject] : {...values[neestedObject], [id]: ""}}));
    } else if(setStateParent && ref.current.id !== 'search') {
      setStateParent(values => ({...values, [id]: ""}));
    } else {
      setStateParent('');
    }
  }

  const handleClassName = () => {
    let classes = 'FormElement-input';
    if(type === 'date' || type === 'checkbox' || type === 'radio' ) {
      classes += ` FormElement-input--${type}`;
    }
    if(className) {
      classes += ` ${className}`;
    }
    return classes;
  }

  return (
    <>
      <input
        ref={ref}
        id={id}
        type={type}
        name={id}
        className={handleClassName()}
        value={value}
        required={required}
        placeholder={placeholder}
        {...props.autoFocus}
        {...props}
      />
      {type !== 'date' && type !== 'checkbox' && type !== 'radio' &&!hideReset ? (
        <button type="button" className="FormElement-reset" onClick={handleResetClick}>
          <Icon.Close />
        </button>
      ) : null}
    </>
  )
}

export default Input;
