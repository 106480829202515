import React from 'react'
import DOMPurify from 'dompurify';

const Label = ({required, className, text, id, showError, description, rawHtml}) => {
  const handleClassName = () => {
    let classes = 'FormElement-label';
    if(className) {
      classes += ` ${className}`;
    }
    if(showError) {
      classes += ` FormElement-label--error`;
    }
    return classes;
  }

  return (
    <>
      <label htmlFor={id} className={handleClassName()}>
        <div>
          <div>
            {text && <span>{text}</span>}
            {rawHtml && <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(rawHtml)}}></span>}
            { required === 'true' ? <sup className="FormElement-required">*</sup> : null}
          </div>
          {description && <span className="FormElement-label--description">{description}</span>}
        </div>
      </label>
      {showError && <span className="FormElement-error">{showError}</span>}
    </>
  )
}

export default Label;
