import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import {BrowserRouter} from 'react-router-dom'
import './i18next'
import GlobalStyle from "./utils/theme/globalStyle";
import { AppProvider } from "./states/appState";
import AmplitudeGlobalDatalayer from './components/Tracking/AmplitudeGlobalDatalayer';
import Loader from 'components/Loader/Index';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.Suspense fallback={<Loader/>}>
		<BrowserRouter>
			<AppProvider>
				<GlobalStyle />
				<App />
				<AmplitudeGlobalDatalayer />
			</AppProvider>
		</BrowserRouter>
	</React.Suspense>
);
