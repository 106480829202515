import React from 'react'
import Layout from "../../utils/layout/Layout.jsx";
import {Trans, useTranslation} from "react-i18next";

function UnsubscriptionConfirmed() {
  const { t } = useTranslation();

  return (
    <Layout title={t('unsubscribeNewsletter.confirmTitle')} containerClass="Profile-content--flCenter Profile-content--text"
            iconHeaderRight="true">
      <p className="Profile-text">
        <Trans i18nKey="unsubscribeNewsletter.confirmText"
               components={{return: <br/>}}>
        </Trans>
      </p>
      <div className={"Profile-actions"}>
        <a className="Button" href="https://www.ligue1.fr" >{t('unsubscribeNewsletter.confirmBtn')}</a>
      </div>
    </Layout>
  )
}

export default UnsubscriptionConfirmed;
