import * as React from "react";

const Loader = ({ titleId, className, ...props }) => (
  <i className={className ? `Icon ${className}` : "Icon"} aria-labelledby={titleId} {...props}>
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" data-name="Calque 1" viewBox="0 0 512 512">
        <g className="spinner">
            <circle cx="256" cy="34.49" r="34.91" style={{opacity:.14}}/>
            <circle cx="366.55" cy="64.04" r="34.91" style={{opacity:.29}}/>
            <circle cx="447.53" cy="145.03" r="34.91" style={{opacity:.43}}/>
            <circle cx="477.09" cy="255.58" r="34.91" style={{opacity:.57}}/>
            <circle cx="447.53" cy="366.12" r="34.91" style={{opacity:.71}}/>
            <circle cx="366.55" cy="447.11" r="34.91" style={{opacity:.86}}/>
            <circle cx="256" cy="476.67" r="34.91"/>
        </g>
    </svg>  </i>
);

export default Loader;
