import React from 'react'

const FormGroup = ({children, className, ...props}) => {
  return (
    <div className={className ? `FormElement-group ${className}` : "FormElement-group"} {...props}>
      {children}
    </div>
  )
}

export default FormGroup;
