async function getCompetitionTeamsAysnc(competition) {
  try {
    const response = await fetch(process.env.REACT_APP_STATIC_RESOURCES_BASE_URL + '/teams/'+ competition + '.json');
    return await response.json();
  } catch (error) {
    console.error("Failed to fetch teams", error);
  }
  return {};
}

async function getCompetitionTeams() {
  const [l1Teams, l2Teams] = await Promise.all([
    getCompetitionTeamsAysnc("L1" ),
    getCompetitionTeamsAysnc("L2" )
  ]);
  return { L1 : l1Teams , L2 : l2Teams  }
}

function groupedTeams(l1Teams, l2Teams) {
  return  [
              {
                  label: l1Teams.name,
                  logo: l1Teams.logo,
                  options: l1Teams.teams ?? []
              },
              {
                  label: l2Teams.name,
                  logo: l2Teams.logo,
                  options: l2Teams.teams ?? []
              }
          ]
}

export { getCompetitionTeams, groupedTeams }
