import { React, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import CompleteProfile from "./pages/Profile/CompleteProfile";
import NotFound from "./pages/Error/404NotFound";
import InternalError from "./pages/Error/500InternalError";
import { useAppState } from "./states/appState";
import MailConfirm from "./pages/MailConfirm/Index";
import MissingEmail from "./pages/MailConfirm/Missing";
import EditProfile from "./pages/Profile/EditProfileInfo";
import { routes } from "./utils/auth0/constants";
import EditOptin from "./pages/Profile/EditOptin";
import Delete from "./pages/Profile/Delete";
import PasswordReset from "./pages/Profile/PasswordReset";
import UnsubscribeNewsletter from "./pages/UnsubscribeNewsletter/Index";
import * as amplitude from "@amplitude/analytics-browser";

export default function App() {
  const [appContext] = useAppState();

  useEffect(() => {
    if (appContext.styles.favIcon) {
      const link = document.querySelector('link[rel="icon"]');
      link?.setAttribute("href", appContext.styles.favIcon);
    }
    if (appContext?.session_token?.amplitude) {
      amplitude.init(appContext?.session_token?.amplitude, {
        defaultTracking: false,
        serverZone: "EU",
        deviceId: appContext?.session_token?.amplitudeId,
      });
    }
  }, [appContext.styles.favIcon, appContext]);

  return (
    <div>
      <Routes>
        <Route path={routes.Home} element={<Home />} />
        <Route path={routes.CompleteProfile} element={<CompleteProfile />} />
        <Route path={routes.ProfileInfo} element={<EditProfile />} />
        <Route path={routes.ProfileOptin} element={<EditOptin />} />
        <Route path={routes.DeleteProfile} element={<Delete />} />
        <Route path={routes.VerifyEmail} element={<MailConfirm />} />
        <Route path={routes.Error} element={<InternalError />} />
        <Route path={routes.MissingEmail} element={<MissingEmail />} />
        <Route path={routes.PasswordReset} element={<PasswordReset />} />
        <Route
          path={routes.UnsubscribeNewsletter}
          element={<UnsubscribeNewsletter />}
        />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </div>
  );
}
