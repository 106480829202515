const languageToLocale = function(languageOrLocale) {
  if (languageOrLocale) {
    const localePrefix = languageOrLocale.toLowerCase().slice(0, 2);
    switch (localePrefix) {
      case 'fr':
        return 'fr-FR';
      case 'en':
        return 'en-GB';
      case 'es':
        return 'es-ES';
      default:
        return 'en-GB'; 
    }
  }
  return "en-GB";
}

async function getCurrentUserAsync({ token, language, includeOptins }) {
  const headers = { Authorization: 'Bearer ' + token };
  const params = new URLSearchParams({
    language: languageToLocale(language),
    includeOptins: !!includeOptins,
  }).toString();

  const response = await fetch(process.env.REACT_APP_FANBASE_API_URL + '/user/me?' + params, { headers });
  if (response.ok) {
    return response.json();
  }
  throw new Error(response.statusText);
}

async function updateCurrentUserAsync({ token, userProfile })  {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify({
      ...userProfile,
      language: userProfile.language ? languageToLocale(userProfile.language) : undefined,
      mainFavoriteClubId: userProfile.mainFavoriteClubId > 0 ? userProfile.mainFavoriteClubId : null,
    }),
  };

  const response = await fetch(process.env.REACT_APP_FANBASE_API_URL + '/user/me/details', requestOptions);
  if (!response.ok) {
    throw new Error(response.statusText)
  }
}

async function anonymizeUser ({ token })  {
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Authorization': 'Bearer ' + token,
    }
  };
  const response = await fetch(process.env.REACT_APP_FANBASE_API_URL + '/user/me/anonymize', requestOptions);
  if (!response.ok) {
    throw new Error(response.statusText)
  }
}

export { getCurrentUserAsync, updateCurrentUserAsync, anonymizeUser }
